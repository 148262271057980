<template>
  <header>
    <img src="@/assets/logo.svg" alt="">
    <nav>
      <ul>
        <li><a href="">Découvrir l'application</a></li>
      </ul>
    </nav>
  </header>
  <section class="floatline">
    <h1>Profitez de vos vacances !</h1>
    <h2>Naviguez <strong>d'où vous voulez,</strong> sans <strong>brûler votre budget</strong></h2>
    <a href="">Télécharger l'application</a>
  </section>
  <section class="section_line">
    <h3><span>Plus le temps de</span> naviguer ?</h3>
    <p>Ne laissez plus votre bateau au port, faites profitez une communauté de passionné et recevez une commission.</p>
  </section>
  <section class="section_line">
    <h3><span>Vivez la passion de l'océan </span><br>à plusieurs</h3>
    <p>Marre de naviguer seul(e) ? Rencontrez des mordues de navigation qui ont tout comme vous l’envie de découvrir ce que l’océan a, à leur offrir.</p>
  </section>
  <section class="section_line">
    <h3>Une envie <span>de naviguer ?</span></h3>
    <p>Ne laissez pas vos vacances tomber à l’eau en préservant votre budget. Retrouvez sur notre plateforme des véhicules nautiques à des prix attractif.</p>
  </section>
  <section class="section_line">
    <h3><span>Louez, </span>soyez payé</h3>
    <p>Obtenez un complément de revenus en louant votre véhicule nautique.</p>
  </section>
  <section class="section_end">
    <div>
      <h3>N'attendez plus ! <br> <span>Larguez les amarres !</span></h3>
      <a href="">Télécharger l'application</a>
    </div>
  </section>
  <footer>
    <small>Larguez les amarres</small>
  </footer>
</template>

<script>
export default {
  name: 'App'
}
</script>
